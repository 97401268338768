<template>
  <Div :darkMode="darkMode">
    <Layout class="layout">
      <Header
        :style="{
          position: 'fixed',
          width: '100%',
          margin: '0 0 0 280px !important',
          top: 0,
          background: '#fff',
          [!rtl ? 'left' : 'right']: 0,
        }"
      >
        <a-row>
          <!--<a-col
            :lg="!topMenu ? 4 : 3"
            :sm="6"
            :xs="12"
            class="align-center-v navbar-brand"
          >
            <sdButton
              v-if="!topMenu || innerWidth <= 991"
              @click="toggleCollapsed"
              type="white"
            >
              <img
                :src="
                  require(`../static/img/icon/${
                    collapsed ? 'right.svg' : 'left.svg'
                  }`)
                "
                alt="menu"
              />
            </sdButton>
            <router-link
              :class="
                topMenu && innerWidth > 991
                  ? 'striking-logo top-menu'
                  : 'striking-logo'
              "
              to="/"
            >
              <img
                :src="
                  !darkMode
                    ? require(`../static/img/them_logo.png`)
                    : require(`../static/img/them_logo.png`)
                "
                alt="logo"
                style="margin-left: 20px;"
              />
            </router-link>
          </a-col>-->
          <a-col :lg="!topMenu ? 11 : 11" :md="8" :sm="0" :xs="0">
            <TopMenu v-if="topMenu && innerWidth > 991" />
            <HeaderSearch v-else />
          </a-col>

          <a-col :lg="8" :md="15" :sm="0" :xs="0">
            <TopMenuSearch v-if="topMenu && innerWidth > 991">
              <div class="top-right-wrap d-flex">
                <a
                  :class="`${
                    activeSearch ? 'search-toggle active' : 'search-toggle'
                  }`"
                  @click="
                    () => {
                      toggleSearch();
                    }
                  "
                  href="#"
                >
                  <sdFeatherIcons type="search" />
                  <sdFeatherIcons type="x" />
                </a>
                <div
                  :class="`${
                    activeSearch
                      ? 'topMenu-search-form show'
                      : 'topMenu-search-form'
                  }`"
                >
                  <form action="">
                    <span class="search-icon">
                      <sdFeatherIcons type="search" />
                    </span>
                    <input type="text" name="search" />
                  </form>
                </div>
                <!-- <AuthInfo /> -->
                <AuthInfo :currentuser="currentuser" :disConnect="disConnect"/>
              </div>
            </TopMenuSearch>
            <!-- <AuthInfo v-else /> -->
            <AuthInfo :currentuser="currentuser" :disConnect="disConnect"/>
          </a-col>
          <a-col :style="{ position: 'static' }" :md="0" :sm="18" :xs="12">
            <div class="mobile-action">
              <a
                class="btn-search"
                @click="handleSearchHide(searchHide)"
                href="#"
              >
                <sdFeatherIcons type="search" v-if="searchHide" />
                <sdFeatherIcons type="x" v-else />
              </a>
              <a class="btn-auth" @click="onShowHide(hide)" href="#">
                <sdFeatherIcons type="more-vertical" />
              </a>
            </div>
          </a-col>
        </a-row>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col :md="0" :sm="24" :xs="24">
            <div class="small-screen-headerRight">
              <SmallScreenSearch :hide="searchHide" :darkMode="darkMode">
                <HeaderSearch />
              </SmallScreenSearch>
              <SmallScreenAuthInfo :hide="hide" :darkMode="darkMode">
                <!-- <AuthInfo :rtl="rtl" /> -->
                <AuthInfo :rtl="rtl" :currentuser="currentuser" :disConnect="disConnect"/>
              </SmallScreenAuthInfo>
            </div>
          </a-col>
        </a-row>
      </div>
      <Layout>
        <template v-if="!topMenu || innerWidth <= 991">
          <Sider
            :width="280"
            :style="{
              margin: '0 0 0 0',
              top: '-01px !important',
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100%',
              borderRadius: '0px 30px 30px 0px !important',
              position: 'absolute',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <AsideItems
                :toggleCollapsed="toggleCollapsedMobile"
                :topMenu="topMenu"
                :rtl="rtl"
                :currentuser="currentuser"
                :darkMode="darkMode"
                :events="onEventChange"
              />

              
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout class="atbd-main-layout" style="margin-top: 64px !important;">
          <Content>
            <Suspense>
              <template #default>
                <router-view v-if="load"></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
            <Footer v-if="load"
              class="admin-footer"
              :style="{
                padding: '20px 30px 18px',
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: '14px',
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
            >
              <a-row>
                <a-col :md="12" :xs="24">
                  <span class="admin-footer__copyright"
                    >© 2021 - {{ currentyear }} Corient Business Solutions Pvt
                    Ltd <br />
                    All rights reserved
                  </span>
                </a-col>
              </a-row>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>
<script>
import HeaderSearch from "../components/header-search/HeaderSearch";
import { Layout ,} from "ant-design-vue";
import {
  Div,
  SmallScreenSearch,
  SmallScreenAuthInfo,
  TopMenuSearch,
} from "./style";
import AuthInfo from "../components/utilities/auth-info/info";
import AsideItems from "./Aside";
import TopMenu from "./TopMenuItems";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { computed, ref, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const { Header, Footer, Sider, Content ,Suspense} = Layout;

export default defineComponent({
  name: "WithAdminLayout",
  components: {
    Div,
    Header,
    Layout,
    Footer,
    Sider,
    Content,
    HeaderSearch,
    SmallScreenSearch,
    SmallScreenAuthInfo,
    TopMenuSearch,
    AuthInfo,
    AsideItems,
    TopMenu,
    PerfectScrollbar,Suspense
  },
  setup() {
    const collapsed = ref(false);
    const hide = ref(true);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);
    const { dispatch, state } = useStore();
    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    collapsed.value = window.innerWidth <= 1200 && true;
    let currentuser = ref(null);
    let roleid = ref(null);
    let currentcompany = ref(null);
    let orgdata = ref(null);
    let current_orgdata = ref(null);
    let load = ref(true);
    var disConnect = ref(false);
    let currentyear = ref(true);
    const { push } = useRouter();

    onMounted(() => {
      currentyear.value = new Date().getFullYear();
      usercheck();
      });
    const disNav = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    //alert(user.clients_connected);
      if (user.clients_connected<=user.connection_limit) {
        disConnect.value=true;
        
      } 
      else {
        disConnect.value=false;
      }
    };
    const usercheck = () => {
    load.value = false;
      let user = JSON.parse(localStorage.getItem("access_token"));
      if (user) {
        dispatch("userdata").then(
          (response) => {
            currentuser.value = response.data;
            load.value = true;
            disNav();
            // push("/");
          },
          (error) => {
            // console.log(error);
            state.auth.login = false;
            push("/auth/login");
            // router.push('/');
          }
        );
      } else {
        state.auth.login = false;
        push("/auth/login");
        // router.push('/');
        // router.push('/');
      }
    };

    // const xeroconnect = () => {
    //   window.location.href = API_ENDPOINT + "xero/authorization.php?app_id=1";
    // };

    // const xerodisconnect = () => {
    //   // alert('test');
    //   dispatch("xerodisconnect").then(
    //     (response) => {
    //     console.log(response);
    //       // orgdata.value = null;
    //       // xeroconect.value = false;
    //       // xerodata.value = null;
    //       // router.push('/');
    //     },
    //     (error) => {
    //       console.log(error);
    //       // router.push('/');
    //       // xeroconect.value = false;
    //     }
    //   );
    //   // window.location.href = API_ENDPOINT + 'xero/authorization.php?app_id=1';
    // };

    const toggleCollapsed = (e) => {
      e.preventDefault();
      collapsed.value = !collapsed.value;
    };
    const handleSearchHide = (search) => {
      searchHide.value = !search;
      hide.value = true;
    };
    const onShowHide = (h) => {
      hide.value = !h;
      searchHide.value = true;
    };
    const toggleSearch = () => {
      // activeSearch.value = !activeSearch.value;
    };

    const toggleCollapsedMobile = () => {
      if (innerWidth <= 990) {
        collapsed.value = !collapsed.value;
      }
    };

    const onRtlChange = () => {
      const html = document.querySelector("html");
      html.setAttribute("dir", "rtl");
      dispatch("changeRtlMode", true);
    };

    const onLtrChange = () => {
      const html = document.querySelector("html");
      html.setAttribute("dir", "ltr");
      dispatch("changeRtlMode", false);
    };

    const modeChangeDark = () => {
      dispatch("changeLayoutMode", true);
    };

    const modeChangeLight = () => {
      dispatch("changeLayoutMode", false);
    };

    const modeChangeTopNav = () => {
      dispatch("changeMenuMode", true);
    };

    const modeChangeSideNav = () => {
      dispatch("changeMenuMode", false);
    };

    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    };
    //console.log(topMenu.value);
    return {
      toggleCollapsed,
      handleSearchHide,
      toggleCollapsedMobile,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      currentuser,
      orgdata,
      current_orgdata,
      load,
      currentyear,
      currentcompany,
      disNav,
      disConnect,
      roleid,
    };
  },
});
</script>
<style>
.ps {
  height: calc(100vh - 100px);
}
</style>
